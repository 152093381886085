import _api from "@/utils/request";

// 获取 公司信息
export function getCompanyDetail() {
  return _api({
    url: "/dev-api/mobile/manage/manageCompany/getDetail",
    method: "get"
  });
}

// 提交 - 留言
export function postMessage(data) {
  return _api({
    url: '/dev-api/mobile/manage/manageLeaveMessage/add',
    method: 'post',
    data
  })
}


// 获取团队列表
export function getTeamList () {
  return _api({
    url: '/dev-api/mobile/manage/manageTeam/list',
    method: 'get',
    data: {pageNum: 1, PageSize: 20}
  })
}

// 企业合作
export function getEnterprises() {
  return _api({
    url: '/dev-api/mobile/manage/manageCooperativeEnterprises/list',
    method: 'get',
    data: {pageNum: 1, PageSize: 20}
  })
}

// 获取 视频列表 通过类型查询视频(类型:0-公司简介视频,1-汽车产品介绍,2-现场视频)
export function getVideos(type) {
  return _api({
    url: `/dev-api/mobile/manage/manageVideo/getByType/${type}`,
    method: 'get'
  })
}

// 获取产品列表
export function getProducts(){
  return _api({
    url: '/dev-api/mobile/manage/manageProduct/list',
    method: 'get',
    data: {pageNum: 1, PageSize: 20}
  })
}

// 获取产品详情
export function getProductDetail (id) {
  return _api({
    url: `/dev-api/mobile/manage/manageProduct/getDetail/${id}`,
    method: 'get'
  })
}
