<template>
  <div v-if="visible" class="dialog">
    <div class="dialog-box">
      <div class="title">
        {{ title }}
        <span @click="close">×</span>
      </div>
      <div class="video">
        <video
          class="video-js vjs-default-skin vjs-big-play-centered"
          id="playerId"
          autoplay
          controls
          controlslist="nodownload"
        >
          <source :src="video_url"  />
        </video>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      disableScroll: true,
      title: '',
      player: null,
      video_url: '',
    }
  },
  mounted() {
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    open(title, url) {
      this.$toTop();
      this.title = title;
      this.video_url = url;
      this.visible = true;
      this.$stopScroll();
    },
    close() {
      this.visible = false;
      this.$canScroll();
    },
  }
}
</script>
<style lang="scss" scoped>
.dialog{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.5);
  .dialog-box{
    width: 80%;
    height: 700px;
    padding-top: 20px;
    padding-bottom: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    .title{
      width: 100%;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 18px;
      color: #222222;
      text-align: center;
      span{
        width: 30px;
        height: 30px;
        display: inline-block;
        margin-right: 20px;
        float: right;
        font-size: 40px;
        color: #000;
        margin-top: -30px;
        cursor: pointer;
      }
    }
    .video{
      width: 96%;
      height: 620px;
      video{
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
