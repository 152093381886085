import axios from 'axios'

const tokenStr = 'jEaslticbXDNKemVqgglIxxUvQbuuuSvdDojzoBQelGdDqAPzJnIUDSWVZMQqzTBJJOfmZYosODGGZVnDzOYVnETZSncuExijwCeEslweTXTRHwqOXHjOoolOGIZAgefvcnxsTYNWXQurbYWrZkmlaxJQgsDWEWJ';

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建axios实例
const _api = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: 'https://www.hgbsai.com/',
  // 超时
  timeout: 10000
})

// request拦截器
_api.interceptors.request.use(config => {
  const date = new Date();
  const year = date.getFullYear(); // 获取当前年份，例如：2021
  const month = date.getMonth() + 1; // 获取当前月份，注意需要加1，例如：9
  const day = date.getDate(); // 获取当前日期，例如：22
  // 调用接口时统一为请求头挂载 token 字段
  config.headers.token = `${tokenStr}${year}${month > 9 ? month : "0"+month}${day > 9 ? day : '0'+day}`;
  return config
}, err => {
  // 对请求错误做些什么
  return Promise.reject(err)
})

// 响应拦截器
_api.interceptors.response.use(res => {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return res.data;
  },
  err => {
    return Promise.reject(err)
  }
)

export default _api
