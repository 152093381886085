import Dialog from './components/Dialog.vue';

export default {
  install(Vue, options) {
    // 创建一个新的Vue实例作为全局对话框
    const DialogConstructor = Vue.extend(Dialog);
    const instance = new DialogConstructor({
      el: document.createElement('div'),
    });

    // 将对话框实例挂载到body上
    document.body.appendChild(instance.$el);
    Vue.prototype.$dialog = instance;
  }
};
