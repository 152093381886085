<template>
  <div class="solution-feedback">
    <div class="feedback   ">
      <div class="desc  wow fadeInLeft">
        {{ $store.state.company_feedback }}
        <p @click="videoDialog">点击此处观看使用现场视频</p>
      </div>
      <div class="photo  wow fadeInRight">
        <img src="../assets/index/client_bg@2x.png" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      content: ''
    }
  },
  mounted() {
    let wow = new this.$wow.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: false
    });
    wow.init();
  },
  methods: {
    videoDialog () {
      /*
      * 公司简介视频 0
        汽车产品介绍 1
        蓝湿皮现场视频 2
        蓝湿皮详情视频 3
        缺陷识别机详情视频 4
      * */
      const store = this.$store.state;
      this.$video.open(store.company_feedback_title, store.company_feedback_video_url);
    },
  }
}
</script>
<style lang="scss" scoped>
.solution-feedback{
  width: 100%;
  height: calc(695px*0.83);
  //height: 695px;
  background: #2F318B;
  display: flex;
  justify-content: center;
  align-items: center;
  .feedback{
    width: 1200px;
    //width: 1440px;
    height: calc(695px*0.83);
    //height: 695px;
    display: flex;
    flex-direction: row;
    .desc{
      //width: 987px;
      width: calc(987px*0.83);
      margin-top: calc(175px*0.83);
      font-weight: 500;
      font-size: 40px;
      color: #FFFFFF;
      line-height: 58px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      p{
        margin-top: 20px;
        //margin-top: 28px;
        font-weight: 500;
        font-size: 16px;
        //font-size: 20px;
        color: #FFFFFF;
        line-height: 39px;
        text-align: left;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .photo{
      margin-top: 175px;
      padding-left: 100px;
      img{
        //width: 360px;
        //height: 360px;
        width: calc(360px*0.83);
        height: calc(360px*0.83);
      }
    }
  }
}
</style>
