<template>
  <div class="footer-box">
    <div class="footer-content">
      <div class="footer">
        <div class="footer-nav">
          <!-- 底部 - 公司信息 -->
          <div class="fn-logo">
            <img class="logo" :src="$store.state.foot_logo" :alt="$store.state.company_name"/>
            <img class="addr" src="../assets/index/adress@2x.png" height="20" width="20"/>
            <span>{{ $store.state.company_address || '江苏省扬州市生态科技新城哈工大机器人(扬州)科创中心2号楼' }}</span>
          </div>
          <!-- 底部 - 导航 -->
          <div class="fn-list">
            <div class="nav-item">
              <div class="ni-title">哈工博视</div>
              <div class="ni-item" @click="jump('home')">首页</div>
              <div class="ni-item" @click="jump('products')">产品中心</div>
              <div class="ni-item" @click="jump('about')">关于我们</div>
            </div>
            <div class="nav-item">
              <div class="ni-title">支持</div>
              <div class="ni-item" @click="jump('product')">产品文档</div>
              <div class="ni-item" @click="jump('contact')">产品定价</div>
            </div>
            <div class="nav-item">
              <div class="ni-title">联系我们</div>
              <div class="ni-item" @click="jump('contact')">在线联系</div>
            </div>
          </div>
        </div>
        <div class="footer-info">
          <div class="fi-contact">
            <div class="fi-tel-email">
              <img class="fi-icon" src="../assets/index/phone@2x.png" />
              <span>{{ $store.state.company_tel }}</span>
              <img class="fi-icon" src="../assets/index/email@2x.png" />
              <span>{{ $store.state.company_mail }}</span>
            </div>
            <div class="fi-back-to-top" @click="toTop">
              <img src="../assets/index/gotop@2x.png" />
            </div>
          </div>
          <div class="fi-qrcode">
            <p>微信扫码添加售前专家咨询</p>
            <div>获取产品说明手册请添加售前专家微信</div>
            <img :src="$store.state.company_qr_code" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer-copyright">
      <div>{{ $store.state.company_copyright }}</div>
      <div>{{ $store.state.company_filing_info }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  },
  methods: {
    jump (type) {
      if (type == 'contact') {
        this.$dialog.open();
      } else {
        this.$router.push({ name: type })
      }
    },
    toTop () {
      this.$emit('btt')
    },
  }
}
</script>
<style lang="scss">
.footer-box{
  //width: 100vw;
  //min-width: 1920px;
  height: 478px;
  background: #0D0D0D;
  padding-top: 10px;
  // 脚部主体样式
  .footer-content {
    //width: 100vw;
    height: 388px;
    display: flex;
    justify-content: center;
    align-items: center;
    .footer {
      width: 1200px;
      //width: 1440px;
      height: 388px;
      display: flex;
      flex-direction: row;
      .footer-nav{
        flex: 1;
        height: 388px;
        .fn-logo{
          padding-top: 14px;
          padding-bottom: 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          border-bottom: 1px solid #4C4C4C;
          .logo{
            width: 166px;
            height: 33px;
            margin-right: 30px;
          }
          .addr{
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
          span{
            font-weight: 500;
            //font-size: 16px;
            font-size: 14px;
            color: #FFFFFF;
          }
        }
        .fn-list{
          padding-top: 37px;
          color:#fff;
          display: flex;
          flex-direction: row;
          .nav-item{
            margin-right: calc(125px*0.83);
            //margin-right: 125px;
            .ni-title{
              padding-bottom: 20px;
              font-weight: 700;
              font-size: 16px;
              color: #FFFFFF;
              text-align: left;
            }
            .ni-item{
              padding: 10px 0;
              font-weight: 400;
              font-size: 12px;
              //font-size: 14px;
              color: #FFFFFF;
              text-align: left;
              cursor: pointer;
              &:hover{
                color: #c7c7c7;
              }
            }
          }
        }
      }
      .footer-info{
        flex: 1;
        height: 388px;
        margin-left: 50px;
        padding-left: 45px;
        border-left: 1px solid #4C4C4C;
        .fi-contact{
          padding-top: 10px;
          padding-bottom: 12px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #4C4C4C;
          .fi-tel-email{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .fi-icon{
              width: 20px;
              height: 20px;
              margin-right: 10px;
            }
            span{
              margin-right: 60px;

              font-weight: 500;
              font-size: 16px;
              color: #FFFFFF;
            }
          }
          .fi-back-to-top{
            cursor: pointer;
            img{
              width: 40px;
              height: 40px;
            }
          }
        }
        .fi-qrcode{
          text-align: left;
          p{
            margin-top: 35px;

            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
          }
          div{

            font-weight: 400;
            font-size: 13px;
            color: #A6A6A6;
          }
          img{
            width: 120px;
            height: 120px;
            margin-top: 25px;
          }
        }
      }
    }
  }
  // 版权信息样式
  .footer-copyright {
    //width: 100vw;
    height: 90px;
    background: #000000;

    font-weight: 400;
    font-size: 14px;
    color: #9E9E9E;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
</style>
